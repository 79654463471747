@tailwind base;

*::-webkit-scrollbar {
    width: 15px;
  }

:root {
    --primary: rgb(24, 24, 27);
    --secondary: yellowgreen;
  }
  
*::-webkit-scrollbar-track {
    background: var(--primary);
    
  }
  
*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 3px solid var(--primary);
  }

html {
    scroll-behavior: smooth;
  }
@tailwind components;
@tailwind utilities;

